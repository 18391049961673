import Vue from 'vue';
import { filtrarAtributosNulosObjeto } from '../helpers/Utilitarios';
export default class SmsService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    filtrarCampanhas(filtros, nr_pagina, nr_email_pagina) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.apresentante = this._store.getters.cdApresentante;
        filtros.pagina = nr_pagina;
        filtros.email_por_pagina = nr_email_pagina;

        return Vue.http.get(url + '/sms/api/sms/', {
            params: filtros
        });
    }

    relatorioCampanhas(filtros, limit = null, offset = null) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.limit = limit;
        filtros.offset = offset;
        filtrarAtributosNulosObjeto(filtros);

        return Vue.http.get(
            url +
                `/sms/api/sms/report/?environment=RECUPERI&apresentante=${this._store.getters.cdApresentante}`,
            {
                params: filtros
            }
        );
    }

    detalharCampanha(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(url + `/sms/api/sms/?id=${campanha.id}&`, {
            params: {
                apresentante: this._store.getters.cdApresentante
            }
        });
    }

    verificaTitulosVinculados(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(
            url +
                `/sms/api/sms-title/?environment=RECUPERI&apresentante=${this._store.getters.cdApresentante}&sms=${campanha.id}`,
            {
                params: {
                    apresentante: this._store.getters.cdApresentante
                }
            }
        );
    }

    salvarCampanha(data) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/sms/api/sms/';
        if (data.id) {
            let payload = {
                id: data.id,
                is_active: data.is_active === true ? 'true' : 'false',
                created_by_title: data.created_by_title
            };
            response = Vue.http.patch(url, payload);
        } else {
            response = Vue.http.post(url, data);
        }
        return response;
    }

    salvarContato(data, campanhaCriada) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        let payload = {
            name: data.name,
            phone: data.phone,
            sms: campanhaCriada
        };
        url = url + '/sms/api/contacts/';
        response = Vue.http.post(url, payload);
        return response;
    }

    salvarContatosTitulos(campanhaCriada, titulosSelecionados) {
        var response = null;
        let payload = {
            sms: campanhaCriada,
            title_ids: titulosSelecionados
        };
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/sms/api/sms-title/?';
        response = Vue.http.post(url, payload);
        return response;
    }

    envioTeste(numero, idCampanha, name) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        let payload = {
            campaign: idCampanha,
            phone: numero,
            name: name
        };
        url = url + '/sms/api/sms-send/';
        response = Vue.http.post(url, payload);
        return response;
    }

    listarContatos(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return Vue.http.get(
            url +
                '/sms/api/contacts/' +
                '?&sms=' +
                campanha.id +
                '&environment=RECUPERI' +
                '&apresentante=' +
                this._store.getters.cdApresentante
        );
    }

    verificaStatus() {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return Vue.http.get(
            url + '/sms/api/sms-template/?environment=RECUPERI&is_active=true'
        );
    }
}
